import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell d’aquest bolet és untuós al tacte i un poc higròfan, és de color marró grisenc, de 2 a 4 cm de diàmetre i amb un mamelló central més fosc. Les làmines són bifurcades a prop del peu, un poc decurrents i blanques que groguegen amb l’edat. Les espores blanques en massa, lleugerament el·líptiques i amb berrugues, de 7-8,1 x 4,5-6 micres. El peu és llarg, grisenc a marró grisenc, més clar cap a la base.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      